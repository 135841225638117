import React from "react";
import styled from "styled-components";
import { H1 } from "app/components/Typography";

export const Title = styled(H1)`
  line-height: 110%;
  margin-right: 32px;
  margin-bottom: 16px;
  padding-bottom: 0;
`;
